angular.module('LeasePilot').service('leaseExportService', [
  'DocumentService',
  'leaseAbstractService',
  'kiteExportService',
  'mriExportService',
  'ApiService',
  '$mdDialog',
  '$rootScope',
  '$timeout',
  '$interval',
  '$http',
  function (
    DocumentService,
    leaseAbstractService,
    kiteExportService,
    mriExportService,
    ApiService,
    $mdDialog,
    $rootScope,
    $timeout,
    $interval,
    $http,
  ) {
    const TIMEOUT = 240000;
    const CHECK_INTERVAL = 2000;
    const backendProcessingFinishedStatuses = [
      'exported',
      'conversion_failed',
      'export_failed',
    ];

    const handleExportError = (error) => {
      return new Promise((_resolve, reject) => {
        console.error('export failed with error:', error);
        $mdDialog.cancel();
        $mdDialog.show({
          template:
            '<md-dialog><show-export-errors-modal ' +
            "error-type='" +
            error.errorType +
            "' " +
            "errors='" +
            JSON.stringify(error.error) +
            "'>" +
            '</show-export-errors-modal></md-dialog>',
          parent: angular.element(document.body),
          clickOutsideToClose: true,
          fullscreen: false,
        });

        reject('exportError');
      });
    };

    const showExportLeaseDialog = () => {
      $mdDialog
        .show({
          template:
            '<md-dialog><export-lease-modal></export-lease-modal></md-dialog>',
          parent: angular.element(document.body),
          clickOutsideToClose: true,
          fullscreen: false,
        })
        .finally(() => {
          $rootScope.$emit('exportLeaseDialogClosed');
        });
    };

    const fetchLastExportTask = function(lease, export_task_type) {
      return new Promise((resolve, reject) => {
        $http({
          method: 'GET',
          url: '/api/export_tasks/last?lease_id=' + lease.id + '&type=' + export_task_type,
          headers: { 'Content-Type': 'JSON' }
        }).then((response) => {
          return resolve(response.data.export_task);
        }).catch((errorResponse) => {
          return reject({
            errorType: 'task_status_check_failed',
            error: _.get(errorResponse, 'data.errors', undefined),
          });
        });
      });
    };

    const handleExportStatusChange = (task) => {
      return new Promise((resolve, reject) => {
        if (!task) {
          return reject({
            errorType: 'task_status_check_failed',
            error: ['No export task created'],
          });
        }
        var timeout = $timeout(() => {
          $timeout.cancel(timeout);
          $interval.cancel(interval);

          return reject({
            errorType: 'task_status_check_timeout',
            error: ['Timeout error'],
          });
        }, TIMEOUT);

        var interval = $interval(
          () => {
            $http
              .get('/api/export_tasks/' + task.id)
              .then((response) => {
                const task = response.data.export_task;
                const status = task.status;
                const checkFinished = _.includes(
                  backendProcessingFinishedStatuses,
                  status,
                );

                if (checkFinished) {
                  $timeout.cancel(timeout);
                  $interval.cancel(interval);
                  return resolve(task);
                }
              })
              .catch((errorResponse) => {
                console.error(errorResponse);
                $timeout.cancel(timeout);
                $interval.cancel(interval);
                return reject({
                  errorType: 'task_status_check_failed',
                  error: _.get(errorResponse, 'data.errors', undefined),
                });
              });
          },
          CHECK_INTERVAL,
          TIMEOUT / CHECK_INTERVAL,
        );
      });
    };

    const exportLease = (leaseId, documentType, docxFile, handleExportComplete) => {
      return DocumentService.get({ id: leaseId, type: documentType }).then(
        (lease) => {
          const company = lease.company;
          const integrationsConfig = company.companyProfile.integrationsConfig;

          const azureIntegrationEnabled = integrationsConfig
            && integrationsConfig.azure
            && integrationsConfig.azure.enabled === true;

          const mriClientIntegrationEnabled = integrationsConfig
            && integrationsConfig.mri
            && integrationsConfig.mri.enabled === true;

          switch (company.name) {
            case "kite": {
              if (
                azureIntegrationEnabled &&
                integrationsConfig.exportStatusId !== null
              ) {
                return startAzureKiteExport(
                  lease,
                  documentType,
                  docxFile,
                  handleExportComplete
                );
              }
              break;
            }
            case "revantage": {
              if (mriClientIntegrationEnabled) {
                return startMriLeaseRevantageExport(
                  lease,
                  documentType,
                  handleExportComplete
                );
              } else if (azureIntegrationEnabled) {
                return startAzureExport({
                  target: 'azure',
                  id: lease.id,
                  type: documentType,
                  document: lease,
                }, handleExportComplete);
              }
              break;
            }
            default: {
              if (azureIntegrationEnabled) {
                return startAzureExport({
                  target: 'azure',
                  id: lease.id,
                  type: documentType,
                  document: lease,
                }, handleExportComplete);
              }
              break;
            }

            // FIXME: disabled due to the issue that this code blocks PUT request to update Lease
            // case 'woodbury':
            //   if (mriClientIntegrationEnabled) return startMriLeaseWoodburyExport(lease, handleExportComplete);
            //   break;
          }

          return new Promise((resolve, reject) => { reject('ExportNotEnabled'); });
        }
      );
    };

    const changeStatus = (leaseId, callback) => {
      console.info('starting status change export');
      showExportLeaseDialog();
      return createTask(leaseId, null, null)
        .then(handleExportStatusChange)
        .then(callback)
        .catch(handleExportError);
    };

    const startAzureKiteExport = (lease, documentType, docxFile, handleExportComplete) => {
      console.info('starting Kite Azure export');
      showExportLeaseDialog();
      return kiteExportService.prepareData(lease, documentType, docxFile)
        .then(kiteExportService.createExportTask)
        .then(handleExportStatusChange)
        .then(handleExportComplete)
        .catch(handleExportError);
    };

    const startAzureExport = (dataToExport, callback) => {
      return execute(dataToExport, callback);
    };

    const startMriLeaseRevantageExport = (lease, documentType, handleExportComplete) => {
      console.info('starting Revantage MRI export');

      const exportToMRI = (lease, primary_lease_id, handleExportComplete) => {
        let unstructured = leaseAbstractService.prepareUnstructuredDataDeprecated();
        if (primary_lease_id)  unstructured['primary_lease_id'] = primary_lease_id;
        unstructured['previous_status_id'] = lease.statusId || lease.status.id;
        return mriExportService.createExportTask({
          leaseId: lease.id,
          unstructuredData: unstructured
        }).then(handleExportStatusChange)
          .then(handleExportComplete)
          .catch(handleExportError);
      }

      if (documentType == 'Amendment') {
        const confirm = $mdDialog.prompt()
          .title('MRI Lease ID for Amendment')
          .textContent('Please enter MRI Lease ID used to create Amendment from')
          .placeholder('Lease ID')
          .ok('Export')
          .cancel('Cancel');

        $mdDialog.show(confirm).then(function(result) {
          exportToMRI(lease, result, handleExportComplete);
        }, function() {
          console.warn("User didn't enter  primary Lease ID , aborting export ..");
          $rootScope.$emit('leaseExportStatusUpdated', null, window.lease_previous_status_id); // reset 'pending' status
        });
      } else {
         exportToMRI(lease, null, handleExportComplete);
      }

    }

    // const startMriLeaseWoodburyExport = (lease, handleExportComplete) => {
    //   console.info('starting Woodbury MRI export');

    //   const asyncRecursiveExportTaskCheck = () => {
    //     return new Promise((resolve, reject) => {

    //       const timeout = $timeout(() => {
    //         $interval.cancel(interval);
    //         return reject({
    //           errorType: 'task_status_check_timeout',
    //           error: ['Last export check timeout error'],
    //         });
    //       }, TIMEOUT);

    //       var interval = $interval(
    //         () => {
    //           fetchLastExportTask(lease, 'MriLeaseWoodbury')
    //           .then((task) =>  {
    //               if (task && task.id) {
    //                 $interval.cancel(interval);
    //                 return resolve(task);
    //               }
    //             })
    //           .catch(handleExportError);
    //         }, CHECK_INTERVAL, TIMEOUT / CHECK_INTERVAL);

    //     });
    //   }

    //   asyncRecursiveExportTaskCheck().then(handleExportStatusChange).then(handleExportComplete).catch(handleExportError);
    // }

    // =====================================================================================
    // =====================================================================================
    //              Following code is a generalization of the code above
    // =====================================================================================
    // =====================================================================================

    const getDocumentURL = function(documentType, documentId) {
      let url;

      if (documentType && documentId) {
        url = '/' + pluralize(documentType).toLowerCase() + '/' + documentId;
      } else {
        url = window.location.href.replace('/edit', '');
      }

      return url;
    }

    const getHTML = async function(url) {
      return new Promise(function(resolve, reject) {
        const iframe = angular.element('<iframe/>', {
          name: 'file-export',
          style:
            'position: absolute; top: 0; left: 0; width: 1px; height: 1px; z-index: -1; opacity: 0;',
          'event-name': 'file-export',
        });
  
        angular.element('iframe[event-name="export"]').remove();
        angular.element(iframe).attr('src', url);
        angular.element('body').append(iframe);

        $rootScope.$on('file-export', function(event, data) {
          resolve(data.file);
        });
      });
    };

    const getWordDocument = async function(htmlBlob) {
      return new Promise(function(resolve, reject) {
        ApiService.htmlToWord(htmlBlob, window.ASSETS_DESIRED_STYLES).then(
          function success(response) {
            const wordDocument = new Blob([response.data], {
              type:
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            });

            return resolve(wordDocument);
          },
          function error(error) {
            return reject({
              errorType: 'html_to_word_conversion_failed',
              error: error,
            });
          },
        );
      });
    };

    const generateFile = async function(dataToExport) {
      const documentType = dataToExport.type;
      const documentId = dataToExport.id;
      const url = getDocumentURL(documentType, documentId);
      const html = await getHTML(url);
      const file = await getWordDocument(html)
      
      return file;
    };

    // ================================================================================

    const generateStructuredData = function(dataToExport) {
      return dataToExport.document;
    };

    const generateUnstructuredData = function() {
      const unstructured = leaseAbstractService.abstract();

      return unstructured;
    };

    const generateData = function(dataToExport) {
      const data = {};

      data.structured = generateStructuredData(dataToExport);
      data.unstructured = generateUnstructuredData();

      return data;
    };

    // ================================================================================

    const createTask = async function(id, file, data) {
      const formData = new FormData();
      
      formData.append('export_task[lease_id]', id);
      formData.append('export_task[from_status]', window.lease_previous_status.id);
      formData.append('export_task[to_status]', window.lease.status.id);
      
      if (file) {
        formData.append('export_task[file]', file);
      } else {
        formData.append('export_task[file]', null);
      }

      if (data && data.unstructured) {
        formData.append(
          'export_task[unstructured_data]',
          JSON.stringify(data.unstructured),
        );
      } else {
        formData.append('export_task[unstructured_data]', null);
      }

      return $http({
        method: 'POST',
        url: '/api/export_tasks',
        data: formData,
        headers: { 'Content-Type': undefined },
        transformRequest: angular.identity,
      })
        .then(function(response) {
          return response.data.export_task;
        })
        .catch(function(errorResponse) {
          return Promise.reject({
            errorType: 'task_creation_failed',
            error: _.get(errorResponse, 'data.errors', undefined),
          });
        });
    };

    const execute = async function(dataToExport, callback) {
      const id = dataToExport.id;
      let file;
      let data;

      async function _execute(dataToExport, callback) {
        showExportLeaseDialog();

        try {
          file = await generateFile(dataToExport);
          data = generateData(dataToExport);
        } catch(ex) {
          handleExportError();
        }

        return createTask(id, file, data)
          .then(handleExportStatusChange)
          .then(callback)
          .catch(handleExportError);
      }

      if (
        window.user.company.companyProfile.inputAmendedLeaseNumberForAmendments && 
        dataToExport.document.type.toLowerCase() === 'amendment'
      ) {
        const confirm = $mdDialog.prompt()
          .title('Insert the amended lease ID in MRI')
          .textContent('')
          .placeholder('Amended lease ID')
          .ok('OK')
          .cancel('Cancel');

        $mdDialog.show(confirm).then(function(result) {
          if (result) {
            dataToExport.document.exAmendedLeaseId = result;
            
            const documentType = pluralize(dataToExport.document.type).toLowerCase();
            const documentId = dataToExport.document.id;
            const url = `/api/${documentType}/${documentId}`;

            DocumentService.$put(url, {
              exAmendedLeaseId: result,
            }).then(response => {
              _execute(dataToExport, callback);
            }).catch(error => {
              console.error(error);
            });
          } else {
            console.warn("User didn't enter primary Lease ID, aborting export");
            $rootScope.$emit('leaseExportStatusUpdated', null, window.lease_previous_status_id); // reset 'pending' status
          }
        }, function() {
          console.warn("User didn't enter primary Lease ID, aborting export");
          $rootScope.$emit('leaseExportStatusUpdated', null, window.lease_previous_status_id); // reset 'pending' status
        });
      } else {
        _execute(dataToExport, callback);
      }
    };

    return {
      exportLease: exportLease,
      changeStatus: changeStatus,
    };
  },
]);
